import { Collapse } from 'bootstrap';

export default function menuToggle() {
    var megaMenu = document.getElementById('mega-menu-collapse');
    var body = document.body;

    const bsCollapse = Collapse.getOrCreateInstance('#mega-menu-collapse', {
        toggle: false
    });

    megaMenu.addEventListener('show.bs.collapse', function () {
        body.classList.add('mega-menu-open');
    });

    megaMenu.addEventListener('hide.bs.collapse', function () {
        body.classList.remove('mega-menu-open');
    });

    // close on escape
    document.addEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
            bsCollapse.hide();
        }
    });

    // when focus leaves the menu, close it
    megaMenu.addEventListener('focusout', function (e) {
        if (!megaMenu.contains(e.relatedTarget)) {
            bsCollapse.hide();
        }
    });
}
