import { trigger } from "./api/helpers";

const module = 'cookiebar';

/**
 * Main function
 */
export default function setupCookieBar() {
   const cookieBar = document.getElementById('js-cookiebar');
   var customize;
   var acceptButton;
   var rejectButton;
   var startup = false;
   var cookiePreferencesSet = false;

   if (!cookieBar) {
      /**
       * If we do not have a cookiebar, still trigger the cookiesAllowed event.
       * This is useful for plugins that depend on this event.
       * @see /resources/views/components/tagmanager/head.twig
       */
      console.debug('No cookiebar found, triggering cookiesAllowed event.');
      trigger('cookiesAllowed', module);
      return;
   }

   // Get the users current cookie selection
   var currentCookieSelection = getCookie();

   /**
    * If cookies are disallowed, delete all the cookies at every refresh
    * @param null
    * @return null
    */
   if (currentCookieSelection == 'CookiesDisallowed') {
      trigger('CookiesDisallowed', module);
      removeCookies();
      setCookie('cookiebar', 'CookiesDisallowed');
   } else if (currentCookieSelection == 'CookiesAllowed') {
      trigger('cookiesAllowed', module);
   }

   /**
    * Load plugin only if needed:
    * show if the "always" parameter is set
    * do nothing if cookiebar cookie is set
    * show only for european users
    * @param null
    * @return null
    */
   startup = true;
   initCookieBar();

   /**
    * Initialize cookieBAR according to the startup / consentGiven values.
    * @return null
    */
   function initCookieBar() {
      var accepted = getCookie();
      if (accepted === undefined) {
         startup = true;
      } else {
         cookiePreferencesSet = true;
         return;
      }

      if (startup === true && cookiePreferencesSet === false) {
         startCookieBar();
      }
   }

   /**
    * Load external files (css, language files etc.)
    * @return null
    */
   function startCookieBar() {
      // done
      acceptButton = document.getElementById('js-cookie-bar-accept'); // done
      rejectButton = document.getElementById('js-cookie-bar-reject'); // done
      customize = document.getElementById('js-cookie-bar-adjust'); // done

      setEventListeners();
      cookieBar.classList.add('cookiebar--visible');
   }


   /**
    * Get Cookie Bar's cookie if available
    * @return {string} cookie value
    */
   function getCookie() {
      var cookieValue = document.cookie.match(/(;)?cookiebar=([^;]*);?/);

      if (cookieValue == null) {
         return undefined;
      } else {
         return decodeURI(cookieValue[2]);
      }
   }

   /**
    * Write cookieBar's cookie when user accepts cookies :)
    * @param {string} name - cookie name
    * @param {string} value - cookie value
    * @return null
    */
   function setCookie(name, value) {
      var exdays = 30;

      var exdate = new Date();
      exdate.setDate(exdate.getDate() + parseInt(exdays));
      var cValue = encodeURI(value) + ((exdays === null) ? '' : '; expires=' + exdate.toUTCString() + ';path=/');
      document.cookie = name + '=' + cValue;
   }

   /**
    * Remove all the cookies and empty localStorage when user refuses cookies
    * @return null
    */
   function removeCookies() {
      // Clear cookies
      document.cookie.split(';').forEach(function (c) {
         document.cookie = c.replace(/^\ +/, '').replace(/\=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
      });

      // Clear localStorage
      if (localStorage !== null) {
         localStorage.clear();
      }
   }

   /**
    * Set button actions (event listeners)
    * @return null
    */
   function setEventListeners() {
      var analytics = false;
      var marketing = false;

      if (document.getElementById('cookiebar-analytics') !== null) {
         analytics = document.getElementById('cookiebar-analytics').checked;
      }

      if (document.getElementById('cookiebar-marketing') !== null) {
         marketing = document.getElementById('cookiebar-marketing').checked;
      }

      acceptButton.addEventListener('click', function () {
         setCookie('cookiebar', 'CookiesAllowed');
         setCookie('cookiebar-analytics', analytics);
         setCookie('cookiebar-marketing', marketing);
         cookieBar.classList.remove('cookiebar--visible');
         trigger('cookiesAllowed', module);

         dataLayer.push({
            'event': 'consentGiven',
            'analytics_storage': analytics,
            'marketing_storage': marketing,
         });
      });

      rejectButton.addEventListener('click', function () {

         setCookie('cookiebar', 'CookiesDisallowed');
         setCookie('cookiebar-analytics', false);
         setCookie('cookiebar-marketing', false);

         trigger('cookiesDisallowed', module);

         cookieBar.classList.remove('cookiebar--visible');

         dataLayer.push({
            'event': 'consentGiven',
            'analytics_storage': false,
            'marketing_storage': false,
         });
      });


      customize.addEventListener('click', function () {
         customize.dataset.opened = true;
      });
   }
}
