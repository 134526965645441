import Swiper from 'swiper';
import { Pagination, Autoplay, A11y, Keyboard } from 'swiper/modules';
export default function homeHero() {
   const sliderElement = document.querySelector('#js-hero-slider');


   if (!sliderElement) {
      return;
   }
   const pagination = sliderElement.querySelector('.slider__pagination');
   var reduced = window.matchMedia('(prefers-reduced-motion: reduce)');


   const swiper = new Swiper(sliderElement, {
      preloadImages: false,
      modules: [Pagination, Autoplay, A11y, Keyboard],
      lazy: {
         loadPrevNext: true,
      },
      keyboard: {
         enabled: true,
         onlyInViewport: false
      },
      a11y: {
         enabled: true,
      },
      speed: 800,
      autoplay: {
         disableOnInteraction: true,
         delay: 5000,
      },
      slidesPerView: 1,
      loopAdditionalSlides: 1,
      disableOnInteraction: false,
      loop: false,
      pagination: {
         el: pagination,
         type: 'bullets',
         clickable: true,

      },
   });

   if (reduced.matches) {
      swiper.autoplay.stop();
   }

   // if focus in slide move to that slide
   const slideFocus = document.querySelectorAll('.hero-slide');
   slideFocus.forEach((slide) => {
      // get index of current slide

      slide.addEventListener('focusin', () => {
         const slideIndex = slide.getAttribute('data-slide-number');
         swiper.slideTo(slideIndex, 400);
      });
   });
}