import { ajaxCall, setTerms } from 'parentThemeScripts/components/archive';
import Dropdown from 'bootstrap/js/dist/dropdown';

export default () => {
    const archives = document.querySelectorAll('.js-archive');
    archives.forEach((archive) => {
        var filter = archive.querySelector('.js-custom-filter');

        if (filter) {
            filter.value = 'all'; // reset filter on launch

            filter.addEventListener('change', function () {
                var terms = document.querySelector('input[name="region"]:checked').value;

                var dropdown = new Dropdown(document.getElementById('filterDropDown'))
                dropdown.hide();

                setTerms(archive, terms);

                ajaxCall(archive, true);
            });
        }
    });
};
