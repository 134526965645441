/**
 * Listeners for archive AJAX calls.
 * 
 * We loop through all archiveElements, find the filter and loadmore button if present and add the listeners accordingly.
 * When interacted with one of the listeners, ajaxCall is being called.
 */
function archiveListeners() {
    const archiveElements = document.querySelectorAll('.js-archive');
    var terms = null;

    archiveElements.forEach((archive) => {
        var loadMoreBtn = archive.querySelector('.js-archive-loadmore-btn'),
            filter = archive.querySelector('.js-filter');

        if (loadMoreBtn) {
            loadMoreBtn.addEventListener('click', function loadmoreBtnHandler() {
                ajaxCall(archive);
            });

            hidePagination(archive);
        }

        if (filter) {
            filter.value = 'all'; // reset filter on launch

            filter.addEventListener('change', function filterHandler() {
                setTerms(archive, this.value);
                ajaxCall(archive, true);
            });
        }
    });
}

/**
 * This function sets the terms filter on the archive element.
 * They can be retrieved by using the getTerms function.
 * 
 * @param {Element} el      The archive element where the action takes place 
 * @param {string} terms    The terms we are filtering on, if any. 
 */
function setTerms(el, terms) {
    var settings = getArchiveSettings(el);
    settings.terms = terms;
}

/**
 * Gets the terms to filter on if any.
 * 
 * @param {Element} el The archive element where the action takes place 
 * @returns string The terms filter on the archive element.
 */
function getTerms(el) {

    var
        settings = getArchiveSettings(el),
        terms = settings.terms ? settings.terms : null;


    if (terms == 'all') {
        terms = null;
    }

    return terms;
}

function getArchiveSettings(el) {
    var block_id = el.dataset.id,
        settings = eval(block_id + '_settings');
    return settings;
}

/**
 * AjaxCall that sends a async request to get the next posts or filter posts by term.
 * 
 * @param {NodeList}    archive         The archive element where the action takes place
 * @param {bool}        termsChanged    Whether the terms filter has changed since the last load event. This will instruct to empty the previous elements form our archive.
 */
async function ajaxCall(archive, termsChanged = false) {

    let
        parameters = {},
        settings = getArchiveSettings(archive),
        action = settings.action,
        url = wplemon.ajax,
        nonce = wplemon.ajax_nonce,
        data = new FormData(),
        loadMoreBtn = archive.querySelector('.js-archive-loadmore-btn'),
        archiveContent = archive.querySelector('.archive-content');

    if (loadMoreBtn) {
        var loadMoreBtnText = archive.querySelector('.js-archive-loadmore-text');
        var loadMoreBtnCount = archive.querySelector('.js-archive-loadmore-count');
        loadMoreBtn.setAttribute('disabled', true);
        loadMoreBtnText.textContent = wplemon.i18n.loading;
    }

    for (var key in settings) {
        if (settings.hasOwnProperty(key)) {
            parameters[key] = settings[key];
        }
    }

    parameters.terms = getTerms(archive);
    parameters.termsChanged = termsChanged ? true : false;

    data.append('action', action);
    data.append('nonce', nonce);
    data.append('parameters', JSON.stringify(parameters));

    try {

        const response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            body: data,
        });

        const res = await response.json();
        if (res.response === 'success') {
            if (termsChanged) {
                archiveContent.innerHTML = '';
            }

            archiveContent.insertAdjacentHTML('beforeend', res.posts);
            settings.postsLoaded = res.posts_loaded;
            settings.postsTotal = res.posts_total;

            if (loadMoreBtn && res.posts_left <= 0) {
                loadMoreBtnText.textContent = wplemon.i18n.noMoreItems;
                loadMoreBtnCount.textContent = '';
                loadMoreBtn.blur();
            } else if (loadMoreBtn) {
                loadMoreBtn.removeAttribute('disabled');
                loadMoreBtnText.textContent = wplemon.i18n.loadMore;
                loadMoreBtnCount.textContent = '(' + res.posts_left + ')';
                loadMoreBtn.blur();
            }



            termsChanged = false; // reset changed after ajax call.
        } else {
            console.error(res);
        }
    } catch (err) {
        console.error(err);
    }
}

/**
 * Function to hide the pagination if present after rendering the archive.
 * This way we have a pagination fallback for users without javascript and for SEO purposes.
 * 
 * @since 4.8.1
 * @api Use this function in your archive template to hide the pagination.
 * @param {Node} archive 
 */
function hidePagination(archive) {

    if (!archive) {
        return;
    }

    const pagination = archive.querySelector('.js-archive-pagination');

    if (pagination) {
        pagination.classList.add('d-none');
    }
}

export { archiveListeners, ajaxCall, getArchiveSettings, setTerms, getTerms, hidePagination };