
var backup = null;
const archiveContent = document.querySelector('.js-faq-archive-content');
export default function faqSearchListeners() {

   if (!archiveContent) {
      return;
   }

   const searchInput = document.querySelector('.js-faq-search-input');
   const searchBtn = document.querySelector('.js-faq-search-btn');
   backup = archiveContent.innerHTML;
   var timeout = null;
   if (searchInput) {
      searchInput.addEventListener('keyup', function (e) {
         clearTimeout(timeout);
         timeout = setTimeout(function () {
            faqAjaxSearch();
         }, 600);
      });
   }

   if (searchBtn) {
      searchBtn.addEventListener('click', function (e) {
         e.preventDefault();
         faqAjaxSearch();
      });
   }

}
var inProgress = false;

async function faqAjaxSearch() {

   var data = new FormData(),
      nonce = wplemon.ajax_nonce,
      url = wplemon.ajax,
      query = document.querySelector('.js-faq-search-input').value,
      initial = null;

   if (archiveContent.dataset.initial) {
      initial = archiveContent.dataset.initial;
   }

   data.append('action', 'faq_search');
   data.append('nonce', nonce);
   data.append('query', query);
   data.append('initial', initial);

   if (inProgress) {
      console.debug('FAQ: in progress');
      return;
   }

   try {
      // only run response if there is no response in progress

      const response = await fetch(url, {
         method: 'POST',
         credentials: 'same-origin',
         body: data,
      });
      inProgress = true;
      const res = await response.json();
      if (res.response === 'success') {
         console.debug('FAQ: ' + res.type);
         archiveContent.innerHTML = '';
         archiveContent.insertAdjacentHTML('beforeend', res.posts);
         inProgress = false;
      } else {
         console.error(res);
         inProgress = false;
      }
   } catch (err) {
      console.error(err);
   }
}


